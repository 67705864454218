<template>
  <v-row class="fill-height">
    <v-col>
      <v-card>
        <v-card-title>
          <v-icon class="mr-2">mdi-handshake</v-icon>
          Benefícios
          <v-spacer></v-spacer>
          <v-text-field
            v-model="benefitPagination.search"
            class="mr-3"
            append-icon="mdi-magnify"
            label="Buscar"
            placeholder="Informe a descrição do beneficio"
            single-line
            hide-details
            @keydown.enter="buscar()"
            @click:append="buscar()"
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>

          <v-alert dense outlined type="warning" v-if="!benefits">
            Benefícios não encontrados
          </v-alert>
        </v-card-text>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="benefits.items"
          :options.sync="options"
          :server-items-length="benefits.totalItems"
          item-key="id"
          class="elevation-1"
          v-show="benefits"
          :footer-props="{
                        'items-per-page-options': [10, 25, 50, 75, 100]
                      }"
        >
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | formatDate2 }}
          </template>
          <template v-slot:item.value="{ item }">
            {{ item.value | money }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#3D1159"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                          name: 'benefit',
                          params: { id: item.id }
                        }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>

      </v-card>
    </v-col>
  </v-row>

</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
// import { mask } from "vue-the-mask";
import {BENEFIT_SEARCH} from "@/store/actions/benefit.type";
import {BENEFIT_RESET_STATE, SET_BENEFIT_PAGINATION} from "@/store/mutations/benefit.type";

export default {
  name: "Benefits",

  // directives: { mask },

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(BENEFIT_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.commit(BENEFIT_RESET_STATE),
      store.dispatch(BENEFIT_SEARCH)
    ]).then(() => {
      next();
    });
  },

  data: () => ({
    selected: [],
    options: {},
    headers: [
      {text: "Data", value: "createdAt"},
      {text: "Empresa", value: "companyName"},
      {text: "Descrição", value: "desc", sortable: false},
      {text: "Status", value: "status"},
      {text: "Valor", value: "value", sortable: false},
      {text: "Visualizar", value: "actions", align: "center", sortable: false}
    ],


  }),
  methods: {
    buscar() {
      const {sortBy, sortDesc, itemsPerPage, page} = this.options

      store.commit(SET_BENEFIT_PAGINATION, {
        page: page,
        itemsPerPage: itemsPerPage,
        orderBy: sortBy,
        sortDesc: sortDesc[0]
      })
      store.dispatch(BENEFIT_SEARCH);
    }
  },
  watch: {
    options: {
      handler() {
        this.buscar()
      }
    }
  },
  computed: {
    ...mapGetters(["checkUsers", "benefits", "benefitPagination"])
  }
};
</script>
